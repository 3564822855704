import { createSelector } from "reselect"
import { getAuthUser } from "../session/selectors"

const teamsSelector = (state) =>
  values(state.event.teams || {}).map((t) => ({
    ...t,
    registerableEvent: eventsSelector(state)[t.registerable_event],
    uncompletedTasks: values(t.tasks)
      .map((t) => !t.fields.every((f) => f.submission))
      .reduce((acc, curr) => acc + (curr | 0), 0),
  }))

export const tagNotifcationsSelector = createSelector(
  teamsSelector,
  getAuthUser,
  (teams, account) => {
    const notifications = {}
    teams.forEach((t) => {
      const notifs = t.uncompletedTasks + t.membership_requests.length
      t.registerableEvent.tags.forEach((tag) => {
        notifications[tag] = (notifications[tag] | 0) + notifs
      })
    })

    notifications["announcements"] = account.is_email_verified ? 0 : 1
    return notifications
  }
)

export const eventsSelector = (state) => state.event.events || {}

function values(obj) {
  return Object.keys(obj)
    .map((k) => obj[k])
    .filter((k) => k)
}

export const teamsByTagSelector = (state, props) =>
  teamsSelector(state).filter((t) =>
    t.registerableEvent.tags.includes(props.tag)
  )

export const announcementsSelector = createSelector(
  (state) => state.event.announcements,
  eventsSelector,
  (announcements, events) =>
    values(announcements || {})
      .map((e) => ({
        ...e,
        event: events[e.event],
      }))
      .filter((a) => !a.event.tags.includes("COMPFEST X"))
)
