import React, { useState } from "react"

import { announcementsSelector } from "../../../redux/slices/event/selectors"
import { connect } from "react-redux"

import { Button } from "jasper-ui"

import { Styles } from "./style"

import attachment from "../imgs/attachment.svg"

const getDate = (infoDate) => {
  const d = new Date(infoDate)
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return `${days[d.getDay()]}, ${d.getDate()} ${
    months[d.getMonth()]
  } ${d.getFullYear()}`
}

const getLinks = (content) => {
  const match = content.match(/(?:<a(?: href=([^>]+)>([^<]+)<\/a>(?!<a)?))+/g)
  if (match) {
    return match
      .map((m) => m.match(/(?:<a(?: href=([^>]+)>([^<]+)<\/a>(?!<a)?))+/i))
      .map(([, href, text]) => ({ href, text }))
  }
  return []
}

const AnnouncementCard = ({ event, content, info_date: infoDate }, idx) => {
  const judul = content ? content.match(/<h3[^>]*>(.*)<\/h3>/i)[1] : "-"
  const contentWithoutJudul = content.replace(/<h3[^>]*>(.*)<\/h3>/i, "")

  const links = getLinks(content)
  const contentWithoutLink = contentWithoutJudul.replace(
    /(?:<a(?: href=([^>]+)>([^<]+)<\/a>(?!<a)?))+/g,
    ""
  )

  return (
    <div key={idx} className="ann-card">
      <div className="ann-card-header">
        <div className="ann-header-left">
          <h2 className="ann-title">{judul}</h2>
          <span>From {event.name}</span>
        </div>
        <div className="ann-header-right">
          <span className="ann-date">{getDate(infoDate)}</span>
        </div>
      </div>
      <div className="ann-card-content">
        <p dangerouslySetInnerHTML={{ __html: contentWithoutLink }} />
        {links.length > 0 ? (
          <div className="links-container">
            {links.map(({ href, text }, i) => (
              <a href={href.replace(/['"]+/g, "")} key={i} className="anchor">
                <img src={attachment} />
                <div className="link">{text}</div>
              </a>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

const DashboardAnnouncement = ({ announcements }) => {
  const [showMore, setShowMore] = useState(false)

  announcements.reverse()

  const firstTwo = announcements.slice(0, 2)

  return (
    <Styles>
      <div className="ann-card-container">
        {announcements.length > 0 ? (
          firstTwo.map(AnnouncementCard)
        ) : (
          <span className="ann-none-warning">
            There are no announcements yet.
          </span>
        )}
        {showMore ? announcements.slice(2).map(AnnouncementCard) : ""}
        {announcements.length > 2 && !showMore ? (
          <Button onClick={() => setShowMore(true)}>Show more</Button>
        ) : (
          ""
        )}
      </div>
    </Styles>
  )
}

const mapStateToProps = (state) => ({
  announcements: announcementsSelector(state),
})

export default connect(mapStateToProps)(DashboardAnnouncement)
