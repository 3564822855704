import styled from "styled-components"

export const Styles = styled.div`
  .ann-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ann-card {
    background-color: #f3f3f3;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: black;
    margin-bottom: 36px;
    width: 100%;
  }

  .ann-card-header {
    background: linear-gradient(94.57deg, #ec544f 0%, #ee965f 100%);
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    color: white;
    padding: 24px 36px;
  }

  .ann-card-content {
    padding: 24px 36px;

    h3,
    h2 {
      margin-top: 0;
    }
  }

  .ann-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ann-title {
    margin-top: 0;
    margin-bottom: 4px;
  }

  .ann-date {
    font-weight: 600;
  }

  button {
    background: transparent;
    color: #ffb23e;
    border: 2px solid #ffb23e;
    border-radius: 50px;
    width: 100%;
  }

  a {
    color: #0083fb;
  }

  .links-container {
    margin-top: 26px;
    a {
      display: flex;
      font-size: 16px;
      color: #0083fb;
      align-items: flex-end;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .link {
      margin-left: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    .ann-card-header {
      flex-direction: column;
    }
  }
`
