import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import DefaultDashboardPage from "../../components/DashboardPage/index-dashboard"
import DashboardAnnouncement from "../../components/DashboardPage/DashboardAnnouncement"

const DashboardIndex = () => (
  <Layout>
    <SEO title="Announcement" />
    <DefaultDashboardPage def="1">
      <DashboardAnnouncement />
    </DefaultDashboardPage>
  </Layout>
)

export default DashboardIndex
